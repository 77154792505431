<template>
  <div>
    <c-dropdown-action
      name="catalog-select"
      class="
        ui-p-4 ui-border ui-rounded
        c-border-gray-200
        focus:c-border-primary
        w-full
        active:c-border-primary
      "
    >
      <template slot="label" slot-scope="props">
        <div class="ui-mr-2 ui-w-full ui-flex">
          <p class="c-text-label c-text-gray-300 ui-text-left">
            Busque ou selecione a classificação ou classificações
          </p>
          <icon
            :name="props.props.isOpen ? 'arrowUp' : 'arrowDown'"
            color="gray"
            class="
              c-icon--right
              ui-ml-auto ui-transition-all ui-duration-100 ui-easy-in
            "
          />
        </div>
      </template>
      <template>
        <div class="c-bg-gray-100" :style="getWidth()" @click.stop="">
          <div class="ui-p-2">
            <c-search-bar
              v-model="filter"
              @search="fetchCatalogs"
            ></c-search-bar>
          </div>
          <div class="max-h-options ui-overflow-auto">
            <div>
              <div
                class="ui-flex c-bg-white"
                v-for="category in categories"
                :key="category.id"
              >
                <TreeViewItem
                  :value="category"
                  :searchChildren="fetchCatalogsChildren"
                  :isLoading="isLoading"
                  :isSelected="isSelected"
                  :changeSelection="selectCatalog"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </c-dropdown-action>
    <div class="ui-flex ui-mt-2">
      <c-pill
        v-for="category in value"
        :key="category.catalog_id || category.id"
        :label="category | categoryName"
        removable
        outline
        color="var(--c-color-gray-400)"
        @remove="removePill(category)"
        class="ui-ml-2"
      />
    </div>
  </div>
</template>

<script>
import {
  CDropdownAction,
  CSearchBar,
  Icon,
  CPill,
} from "@estrategiahq/coruja-web-ui";

import TreeViewItem from "./TreeViewItem.vue";
export default {
  components: {
    CDropdownAction,
    CSearchBar,
    Icon,
    CPill,
    TreeViewItem,
  },
  props: {
    id: {
      type: String,
      default: "catalog-select",
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      categories: null,
      filter: "",
      isLoading: false,
      timeout: null,
    };
  },
  async mounted() {
    await this.fetchCatalogs();
  },
  filters: {
    categoryName(category) {
      return category.path_name
        ? category.path_name.replaceAll("[??]", ": ")
        : category.title;
    },
  },
  watch: {
    async filter() {
      clearTimeout(this.timeout);
      this.timeout =  setTimeout(async () => await this.fetchCatalogs(), 800);
    },
  },
  methods: {
    async fetchCatalogs() {
      try {
        this.isLoading = true;
        let search = "";
        if (!this.baseCastCatalogs) {
          try {
            let castCatalogs = await this.$s.toggler.getCastKeys();
            this.baseCastCatalogs = castCatalogs["subjectcatalogs"]?.split(",");
          } catch (error) {
            console.log("Fail to get catalogs to cast");
          }
        }

        if (this.filter) {
          search += `?name=${encodeURIComponent(this.filter)}`;
        }
        if (!this.baseCastCatalogs) {
          let result = await this.$s.catalog.fetchCategories("", search);
          this.categories = result.data;
        } else {
          let categories = [];
          for (let cat of this.baseCastCatalogs) {
            let result = await this.$s.catalog.fetchCategories(cat, search);
            categories.push(result.data);
          }
          this.categories = categories;
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error.response);
      }
    },
    async fetchCatalogsChildren(id) {
      try {
        this.isLoading = true;
        let search = "";

        if (this.filter) {
          search += `?name=${encodeURIComponent(this.filter)}`;
        }
        let result = await this.$s.catalog.fetchCategories(
          id + "/search",
          search
        );
        this.addChildren({ categories: this.categories }, id, result.data);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error.response);
      }
    },
    // faz busca em largura pelo Id da categoria e adiciona os filhos nos items da categoria
    addChildren(data, id, children) {
      if (!data || !data.categories) return;
      let index = data.categories.findIndex((x) => x.id === id);
      if (index >= 0) {
        data.categories[index].items = children;
      } else {
        for (let cat of data.categories) {
          this.addChildren({ categories: cat.items }, id, children);
        }
      }
    },
    isSelected(id) {
      return this.value.findIndex((x) => x.catalog_id === id) !== -1;
    },
    selectCatalog(isSelected, category) {
      if (isSelected) {
        this.$emit(
          "input",
          this.value.concat({
            catalog_id: category.id,
            title: category.name,
            path_name: category.path_name,
          })
        );
      } else {
        this.$emit(
          "input",
          this.value.filter((x) => x.catalog_id !== category.id)
        );
      }
    },
    removePill(category) {
      this.$emit(
        "input",
        this.value.filter(
          (x) => x.id + x.catalog_id !== category.id + category.catalog_id
        )
      );
    },
    getWidth() {
      let el = document.getElementById("catalog-select");
      if (el) {
        return { width: el.offsetWidth + "px" };
      }
      return { width: "500px" };
    },
  },
};
</script>

<style>
#catalog-select {
  width: 100%;
  display: block;
}

.pill-text-color {
  color: var(--c-color-gray-400) !important;
}
.max-h-options {
  max-height: 224px;
}
</style>